/* card */
.card {
    border: 0 !important;
    background-color: var(--theme) !important;
}

.list-group-item {
    background-color: transparent !important;
}

.hover-card {
    transition: 1s all !important;
}

.hover-card:hover {
    scale: 1.03;
    cursor: pointer;
}

/* responsive */
@media screen and (max-width: 600px) {
    .card-title {
        font-size: 22px !important;
    }

    #progogram-modal .card-title {
        font-size: 16px !important;
    }
}