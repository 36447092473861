/* modal */
.modal-dialog::-webkit-scrollbar,
.modal::-webkit-scrollbar, .modal-body::-webkit-scrollbar {
    display: none;
}

.modal-content {
    color: var(--title) !important;
    background-color: var(--theme) !important;
    border-top: 3px solid var(--title) !important;
    border-radius: 20px !important;
}
