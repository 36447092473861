.item-card {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.card-description {
    padding-left: 20px;
    display: -webkit-box !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
}

.item-toggle {
    width: 53px;
    height: 50px;
    padding-top: 2px;
    left: 10px;
    bottom: -15px;
    background-color: var(--theme);
}

.logo-small {
    width: 48px;
    height: 48px;
    object-fit: cover;
}