/* buttons */
.btn-site {
    color: var(--text) !important;
    background-color: var(--title) !important;
}

.btn-outline-site, .btn-outline {
    color: var(--title) !important;
    border-color: var(--title) !important;
    background-color: var(--theme) !important;
}

.btn-outline-site:hover {
    color: var(--theme) !important;
    font-weight: bold !important;
    background-color: var(--title) !important;
}