/*  logo */
.logo {
    height: 40px !important;
    width: 70px !important;
    object-fit: cover;
}

.logo-medium {
    height: 50px !important;
}

.logo-big {
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.navbar-nav {
    flex-direction: row !important;
    justify-content: space-between !important;
}

.active {
    font-weight: 600;
    color: var(--title) !important;
}

.select-category {
    border: 1px solid var(--title) !important;
}

.select-category .text-white {
    color: var(--title) !important;
}

.category-select {
    font-size: 18px !important;
    font-weight: bold !important;
}

@media screen and (max-width: 900px) {
    .header {
        margin-bottom: 50px !important;
    }

    .header-menu {
        margin-bottom: 90px !important;
    }

    .navbar {
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        z-index: 1030 !important;
        border-radius: 0 0 30px 30px !important;
    }
}
